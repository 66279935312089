
/**
 * @name: availableInventory
 * @author: Gzm
 * @date: 2023-07-27 09:51
 * @description：销售管理-商品可售库存
 * @update: 2023-07-27 09:51
 */
import {Vue, Component} from "vue-property-decorator"
import {ICrudOption} from "@/types/m-ui-crud";
import {
  mallGoodsStockExportApi,
  mallGoodsStockQueryByPageApi,
  mallGoodsStockDetailApi,
  mallGoodsStockInventoryApi
} from "@/apis/site/productStock"
import {IProductStockParam} from "@/apis/site/productStock/types"
import {mallSiteQueryByListApi} from "@/apis/site/list"
import {deepCopy, exportFile} from "@/utils/common";
import {
  goodsStockModifyApi,
  goodsStockQuerySaleByPageApi,
  goodsStockSaleExportApi
} from "@/apis/sale/availableInventory";
import {IGoodsStockQuery} from "@/apis/sale/availableInventory/types";


@Component({})
export default class productStockPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: any[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: IGoodsStockQuery = {
    page: 1,
    limit: 10,
    saleStockSort: 1
  }
  editDialog = false
  // 表单数据
  modelForm: Partial<any> = {}
  // 配置信息
  crudOption: ICrudOption = {
    dialogWidth: '600px',
    editTitle: "库存盘点",
    labelWidth: '100px',
    // @ts-ignore
    editBtnTitle: "提 交",
    menu: false,
    column: [
      {
        label: "商品",
        prop: "goodsKeywords",
        hide: true,
        search: true,
        editHide: true,
        placeholder: "输入商品ID/名称模糊搜索"
      },
      {
        label: "ID",
        prop: "goodsId",
        align: "left",
        width: 180,
        editHide: true
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center",
        span: 12,
        editSlot: true
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        search: true,
        type: "select",
        editHide: true,
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          }
        ]
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        search: true,
        placeholder: "请选择商品分类",
        type: "select",
        dicUrl: '/api/admin/productSort/dropDown',
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
      },
      {
        label: "商品分类",
        prop: 'sortName',
        align: "center",
      },
      {
        label: "商品售价(元/份)",
        prop: "goodsPrice",
        type: "number",
        align: "center",
      },
      {
        label: "商品介绍标签",
        prop: "goodsLabel",
        type: "number",
        align: "center",
        slot: true
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
        span: 12,
        editSlot: true
      },
      {
        label: "站点",
        prop: "siteId",
        search: true,
        type: "select",
        dicData: [],
        hide: true,
        editHide: true
      },
      {
        label: "商品库存",
        prop: "stockQty",
        type: "number",
        align: "center",
      },
      {
        label: "可售库存",
        prop: "saleQty",
        align: "center",
        editHide: true,
        // @ts-ignore
        sortable: "custom",
        slot: true
      },
      {
        label: "商品活动",
        prop: "goodsActivity",
        type: "number",
        align: "center",
        slot: true
      },
      {
        label: "状态",
        prop: "goodsStatus",
        editHide: true,
        search: true,
        type: "select",
        align: "center",
        width: 100,
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          }
        ]
      }
    ]
  }

  getList() {
    this.tableLoading = true
    goodsStockQuerySaleByPageApi(this.queryForm).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  getSiteSelect() {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  /**
   * 导出excel
   */
  exportExcel () {
    goodsStockSaleExportApi(this.queryForm).then(e => {
      exportFile(e, '商品可售库存.xlsx')
    })
  }

  editHandle(row:any){
    this.modelForm.saleQty = row.saleQty
    this.modelForm.id = row.id
    this.editDialog = true
  }

  submit(){
    if (!/^[1-9]{1}[0-9]{0,3}$/.test(this.modelForm.saleQty)) {
      return this.$message.error('应输入大于0小于9999的正整数')
    }
    goodsStockModifyApi(this.modelForm).then(e => {
      if (e){
        this.$message.success('修改成功！')
        this.editDialog = false
        this.getList()
      }
    })
  }

  sortChange(e: any) {
    this.queryForm.page = 1
    if (e.order === "ascending") {
      // 升序
      this.queryForm.saleStockSort = 1
    } else {
      // 降序
      this.queryForm.saleStockSort = 2
    }
    this.getList()
  }

  handleChang(){
    this.$forceUpdate();
  }

  created() {
    this.getList()
    this.getSiteSelect()
  }
}
