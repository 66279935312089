import {get, postJ} from "@/request";
import {IEditGoodsStock, IGoodsStockQuery} from "@/apis/sale/availableInventory/types";
import {IProductStockParam} from "@/apis/site/productStock/types";
import {IOrderManage, IOrderManageParam} from "@/apis/site/orderManage/types";
import {IPageRes} from "@/apis/page";

/**
 * @name: index
 * @author: Gzm
 * @date: 2023-07-27 09:54
 * @description：index
 * @update: 2023-07-27 09:54
 */
/**
 * 商品可售库存分页查询
 * @param query
 */
export const goodsStockQuerySaleByPageApi = (query: IGoodsStockQuery) => get("/mall/goodsStock/querySaleByPage", query)

/**
 * 商品可售库存导出Excel
 * @param query
 */
export const goodsStockSaleExportApi = (query: IGoodsStockQuery) => get("/mall/goodsStock/saleExport", query, "blob")

/**
 * 商品可售库存修改
 * @param query
 */
export const goodsStockModifyApi = (query: IEditGoodsStock) => postJ("/mall/goodsStock/modify", query, )

/**
 * 打印机分页查询
 * @param params
 * @returns
 */
export const printersQueryApi = (params: any) => get("/mall/printers/sn/query", params)

export const printersRemoveApi = (id: string) => get("/mall/printers/sn/remove/" + id)

export const printersDetailApi = (id: string) => get("/mall/printers/sn/detail/" + id)

export const printersCreateApi = (data: any) => postJ("/mall/printers/sn/create",data)

export const printersModifyApi = (data: any) => postJ("/mall/printers/sn/modify",data)

export const printersActivationApi = () => get("/mall/printers/sn/activation")
